.months>.ml-10 {
    margin-left: 10px !important;
}

.w-33 {
    width: 33% !important;
}

.f-w-600 {
    font-weight: 600;
}

.mt-5{
    margin-top: 20px;
}

.bg-white {
    background-color: #fff !important;
}

.table-header-clr {
    background-color: #f0f0f5 !important;
}

.bg-not-paid {
    background-color: #ffc8c8;
}

.bg-full-paid {
    background-color: #b5ffc6;
}

.mh-150 {
    min-height: 150px;
}

.p-5px {
    padding: 5px !important;
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 180px;
    /* or any desired width */
}

.sidebar-overflow {
    overflow-y: auto;
    max-height: 85vh;
}

.student-form-input {
    border: 0;
    border-bottom: 1px solid;
}

#admission_form_for_user * {
    color: black;
  }

  .btn-circle-success {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    text-align: center;
    line-height: 50px;
    padding: 0;
    margin-right: 5px;
    color: white;
    background-color: #44b644;
    border: none;
}

.btn-circle-close{
    width: 50px;
    height: 50px;
    border-radius: 25px;
    text-align: center;
    line-height: 50px;
    padding: 0;
    background-color: #f55959;
    color: white;
    border: none;
}

.action-btns{
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}