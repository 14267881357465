.formDiv{
    /* background-color: aqua; */
    padding: 2%;
    border: 1px solid #eeeeee;
}

.formDiv table{
    width: 100%;
}

.formDiv table td, th {
    border: 1px solid black;
  }

  .formDiv table th {
    padding: 10px 5px;
  }

  .p-150-0{
    padding-bottom: 150px;
  }

  .ta-l{
    text-align: left;
  }

  .ta-c{
    text-align: center;
  }

  .ta-r{
    text-align: right;
  }

.w-100{
    width: 100%;
}

.w-8{
    width: 8%;
}

.w-12{
    width: 12%;
}

.w-33{
    width: 33%;
}

.f-w-600{
    font-weight: 600 !important;
}