.logo a img {
    display: none;
}

.switcher {
    width: 50px;
    height: 50px;
    display: inline-block;
    margin: 4px 8px;
    border-radius: 5px;
    border: 0px solid #000;
}

.demo_changer {
    z-index: 9999;
    position: fixed;
   inset-inline-end: -270px;
    font-weight: 400 !important;
    height: 100%;
}

.purchase {
    text-decoration: underline;
    color: #4BACC6
}

.demo_changer p {
    margin: 10px 0;
}

.demo_changer .dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus {
    background: #fff;
}

.demo_changer select:active,
.demo_changer select:focus,
.demo_changer select:hover,
.demo_changer .button:active,
.demo_changer .button:focus,
.demo_changer .button:hover,
.demo_changer .btn:active,
.demo_changer .btn:focus,
.demo_changer .btn:hover,
.demo_changer .button:active,
.demo_changer .button:focus,
.demo_changer .button:hover {
    box-shadow: none !important;
}

.demo_changer .button {
    background-image: none;
    border: 1px solid #ddd;
    border-radius: 3px;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    background: #fff;
    line-height: 1.42857;
    margin-block-end: 0;
    width: 145px !important;
    padding: 6px 12px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
}

.demo_changer .img-thumbnail {
    height: 35px;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-block-end: 5px;
    width: 46% !important;
    padding: 2px;
}

.demo_changer img {
    border: 4px solid #f4f7fe;
    margin: 2px;
    border-radius: 8px;
    background: #ffffff;
}

.demo_changer hr {
    border-color: #E0E0E0;
}

.demo_changer,
.demo_changer p {
    color: #263871 !important;
}

.demo_changer h4 {
    font-size: 14px;
    padding-block-end: 0;
    text-align: start;
    padding: 10px;
    font-weight: 600;
    border-block-end: 1px solid #e9edf4;
    background: #ebeff8;
    border-block-start: 1px solid #e9edf4;
    margin: 10px 0 !important;
    text-transform: uppercase;
}

.demo_changer i {
    color: #fff;
    font-size: 21px;
    margin-block-start: 10px;
}

.demo_changer .demo-icon {
    cursor: pointer;
    text-align: center;
    padding: 2px;
    float: left;
    height: 40px;
    line-height: 38px;
    width: 40px;
    border-start-start-radius: 5px !important;
    border-start-end-radius: 0px !important;
    border-end-end-radius: 0px !important;
    border-end-start-radius: 5px !important;
    top: 17rem;
    position: relative;
}

@media (max-width: 992px) {
    .demo_changer .form_holder {
        height: 100vh;
    }
}

@media (min-width: 992px) {
    .demo_changer .form_holder {
        height: 100vh;
    }
}

.demo_changer .form_holder {
    background: #fff;
    border-radius: 0;
    cursor: pointer;
    float: right;
    font-size: 12px;
    padding: 0 7px;
    width: 270px;
    box-shadow: 0px 8px 14.72px 1.28px rgba(130, 130, 134, 0.2);
    border-inline-start: 1px solid #e9edf4;
}

.demo_changer .form_holder p {
    font-size: 12px;
}

.predefined_styles {
    padding: 0 5px;
    text-align: center;
}

@media only screen and (min-width: 768px) {
    .demo_changer {
        top: 0;
    }
    .demo_changer .demo-icon {
        height: 40px;
        width: 40px;
    }
    .demo_changer i {
        font-size: 17px;
    }
}

@media only screen and (max-width: 768px) {
    .demo_changer {
        top: 0px;
    }
    .demo_changer i {
        font-size: 21px;
        margin-block-start: 8px;
    }
    .demo_changer .demo-icon {
        height: 40px;
        width: 40px;
    }
    .demo_changer {
        display: block;
    }
}
@supports (-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px)){
    .transparent-mode .demo_changer .form_holder{
        background-color: transparent !important;
        border-inline-start: 1px solid rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(150px);
    }
}
.transparent-mode .demo_changer h4 {
    border-block-end: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.2);
    border-block-start: 1px solid rgba(255, 255, 255, 0.1);
    color: #fff;
}
.transparent-mode .demo_changer, .transparent-mode .demo_changer p {
    color: rgba(255, 255, 255, 0.8) !important;
}
.dark-mode .demo_changer .form_holder{
    background-color: #2a2a4a;
    border-inline-start: 1px solid rgba(255, 255, 255, 0.1);
}
.dark-mode .demo_changer h4 {
    border-block-end: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.2);
    border-block-start: 1px solid rgba(255, 255, 255, 0.1);
    color: #fff;
}
.dark-mode .demo_changer, .dark-mode .demo_changer p {
    color: rgba(255, 255, 255, 0.8) !important;
}
.dark-mode  .input-color-picker {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-color: transparent;
}
.dark-mode  .input-dark-color-picker {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-color: transparent;
}
.dark-mode  .input-transparent-color-picker {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-color: transparent;
}
.transparent-mode  .input-color-picker {
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: transparent;
}
.transparent-mode  .input-dark-color-picker {
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: transparent;
}
.transparent-mode  .input-transparent-color-picker {
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: transparent;
}
.transparent-mode .demo_changer img {
    border: 1px solid rgba(255, 255, 255, 0.5);
    background: transparent;
}
.dark-mode .demo_changer img {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: transparent;
}
.bg-img1 .form_holder{
    /* background-image: url(../../images/media/bg-img1.jpg) !important; */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.bg-img2 .form_holder{
    /* background-image: url(../../images/media/bg-img2.jpg) !important; */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.bg-img3 .form_holder{
    /* background-image: url(../../images/media/bg-img3.jpg) !important; */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.bg-img4 .form_holder{
    /* background-image: url(../../images/media/bg-img4.jpg) !important; */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
   
@media screen and (max-width:991px) {
    .leftmenu-styles {
        display: none;
    }
}

@media screen and (max-width:576px){
    .card.sidebar-right1 .card-body .switch_section{
        padding-inline-end: 0;
    }
}

/* licenses */
.tx-26{
    font-size: 26px;
}
.tx-14 {
    font-size: 14px;
}
.tx-12 {
    font-size: 12px;
}
.font-weight-semibold {
    font-weight: 500;
}
.w-50 {
    width: 50% !important;
  }
  
  .w-55 {
    width: 50% !important;
  }
  
  .w-xs {
    min-width: 90px;
  }
  
  .w-sm {
    min-width: 110px;
  }
  
  .w-md {
    min-width: 130px;
  }
  
  .w-lg {
    min-width: 150px;
  }
  .w-xl {
    min-width: 190px;
  }
  
  .buynow .money-bag,  .free-img{
    margin: 0 auto;
    display: block;
    margin-top: -20px;
  }
  
  .buynow .dropdown-item {
    display: block;
    width: 100%;
    padding:0.45rem .80rem;
    clear: both;
    font-weight: 400;
    color: #576074;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-size: 15px;
  }
  .buynow .dropdown-item:hover{
    background-color: #f5f4ff !important;
  }
  .buynow .btn{
    padding: 0.375rem 0.75rem;
  }
  .buynow .dropdown-menu{
    border-radius: 5px !important;
      background-color: #ffffff;
      border: 1px solid #ffffff8a;
    box-shadow: 0 0px 90px rgb(155 153 153 / 48%) !important;
  }
  .buynow .card-block{
    border: 1px solid rgb(231 229 245 / 11%) !important;
      box-shadow: 0 0.15rem 1.75rem 0 rgb(255 255 255 / 6%);
      top: 0;
      bottom: 0;
      background:#6a1cbb69;
      left: 0;
      border-radius: 7px;
      width: 100%;
      height: 100%;
      position: absolute;
      inset-inline-end: 0;
      bottom: 0;
      inset-inline-start: 0;
  }
  
  @media (max-width: 480px) {
    .buynow .card .btn {
      display: initial !important;
    }
  }
  .buynow.modal .btn-close:focus{
   box-shadow: none;
  }
  .buynow .modal-content {
    position        : relative;
    display         : flex;
    flex-direction  : column;
    width           : 100%;
    pointer-events  : auto;
    background-color: #000000a8;
    background-clip : padding-box;
    border          : 1px solid rgba(0, 0, 0, 0.2);
    border-radius   : 1rem;
    outline         : 0;
  }
  
  .buynow .modal-dialog .image-top {
    margin-top: -10px !important;
  }
  .buynow .modal-dialog .card-block{
    background: #6a1cbbbf;
  }
  .buynow .regular-license {
    z-index: 9;
  }
  .border-0{
    border: 0px !important;
  }
   .modal .modal-header {
    padding: 10px 20px;
  }
  @media (max-width: 480px){
    .buynow .modal-dialog  .modal-body{
      margin: 15px;
    }
  }
  .buynow .modal-dialog  .btn-close{
    position: absolute;
    float: right;
    margin-right: auto;
    right: 33px;
    top: 30px;
    padding:4px;
    background-image: none;
    border: 1px solid #ffffff47;
    border-radius: 5px;
    line-height: 1;
  }
  .buynow .modal-dialog  .btn-close span{
    color: #fff;
    font-size: 24px;
    padding: 9px 0px;
    line-height: .6;
  }
   .lh-xs {
    line-height: 0.75 !important;
    margin-top: 6px;
  }
  .buynow .license-view{
    text-align: center;
    text-decoration: underline;
  }
  .buynow .license-view a{
       padding: 10px;
      border-radius: 5px;
  }
  .buynow .license-view a:hover{
       padding: 10px;
      background-color: rgb(255 255 255 / 12%);
      border-radius: 5px;
  }
  .bg-image-style {
    background-image: url(../img/bg3.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    inset-block-start: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    position: relative;
    border-radius: 0px;
    z-index: 0;
  }
  .bg-image-style::before{
    content: "";
     background-color: #ffffffa6;
     width: 100%;
     height: 100%;
     left: 0%;
     top: 0;
     position:absolute;
    z-index: -1;
  }
  .buynow .modal-backdrop{
    background-color: #000000b8;
  }
  .buynow .card-body.imag-list{
    overflow: visible;
  }
  .buynow .imag-list{
    position: relative;
    content: "";
    left: 0;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    width: 100%;
    height: 100%;
    border-radius: 7px;
    overflow: hidden;
    z-index: 1;
  
  }
  .buynow .imag-list::before{
    content: "";
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 7px;
    position: absolute;
    background: #6a1cbb82;
    width: 100%;
    height: 100%;
    z-index: -1;
    }
    .buynow .modal-content {
      z-index: 0;
      border: 0px #000 solid !important;
  }
  .buynow  .modal-content::before{
      content: "";
      content: "";
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      top: 0;
      left: 0;
      right: 0;
      border-radius:1rem;
      position: absolute;
      background: #000000b3 ;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
    .buynow .Licenses-img{
      border: 1px solid #ffffff2e;
      border-radius: 7px;
    }
    .modal.buynow .modal-body .card{
      background: transparent;

    }

